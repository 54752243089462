import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const GalvanizHasirTelPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Galvaniz Hasır Tel - Erez Kaynak'
        description='Firmamız sıcak daldırma galvaniz yada elektro galvaniz malzeme kullanarak punta kaynaklı galvaniz hasır tel imalatı yapmaktadır. Galvaniz hasırlar normal çelik hasırlara göre çevre şartlarına karşı dirençlidir ve pas yapmaz. Tel kalınlığı 2mm den 8mm tel kalınlığına kadar galvaniz hasır tel imalatını yapabiliyoruz.'
        keywords='galvaniz hasır tel,galvaniz hasır tel fiyatları,galvaniz tel hasır,galvaniz kafes tel,galvaniz hasır,galvaniz puntalı tel,galvaniz kafes tel fiyatları,galvaniz fens,galvaniz kafes tel imalatı,galvanize hasır tel,galvanize hasır tel fiyatları,galvaniz hasır tel kullanım alanları,galvanize hasır tel kullanım alanları,galvanize tel hasır,galvanize kafes tel,galvanize hasır,galvanize puntalı tel,galvanize kafes tel fiyatları,galvanize fens,galvanize kafes tel imalatı'
        url='galvaniz-hasir-tel'
        imgurl={getImageByName('galvanizhasirtel4').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('galvanizhasirtel4').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Galvaniz Hasır Tel' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>sıcak daldırma galvaniz</strong> ya da <strong>elektro galvaniz</strong> malzeme kullanarak{' '}
                <strong>punta kaynaklı galvaniz hasır tel</strong> imalatı yapmaktadır.
              </p>
              <p>
                <strong>Galvaniz hasırlar</strong> normal çelik hasırlara göre çevre şartlarına karşı dirençlidir ve pas yapmaz.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>galvaniz hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>galvaniz hasır tel</strong> imalatı
                yapılabilir.
              </p>
              <p>
                15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz her ölçüde <strong>galvaniz hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>galvaniz hasır telleri</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>
                Galvaniz hasır tellere Elektrostatik toz fırın boya işlemi yapılabilmektedir. Standart yada özel renk seçenekleri ile hasır
                teller elektrostatik toz boya ile boyanmaktadır. Firmamız bünyesinde boyasız olarak hasır tel imalatı gerçekleştirip,
                çalışmış olduğumuz elektrostatik toz boya yapan firmalar ile ihtiyacınız karşılanmaktadır.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('galvanizhasirtel4')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Galvaniz Hasır Tel Kullanım Alanları' />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default GalvanizHasirTelPage
